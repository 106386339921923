import React, { Component } from 'react';
import { array, bool, func, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import config from '../../config';
import { IconSpinner, Form, PrimaryButton, ResponsiveImage, AvatarMedium } from '../../components';
import EstimatedBreakdownMaybe from '../BookingTimeForm/EstimatedBreakdownMaybe';
import InputRow from './InputRow';
import { vatHelper } from '../../containers/AddExtraPage/VatHelper';
import { languagesHelper as language } from '../../helpers/languages';

import {
  selectedExtraValues,
  selectedBedroomsHelper,
  selectedExtraServicesHelper,
  selectedExtrasObj,
} from '../../helpers/extraServicesHelper';

import css from './BookingAddExtraForm.module.css';

const EXTRAS_TYPES = config.custom.extrasTypes;
export class BookingAddExtraFormComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showMore: false,
    };

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
    this.clickShowMore = this.clickShowMore.bind(this);
  }

  handleFormSubmit(e) {
    this.props.onSubmit(e);
  }

  // When the values of the form are updated we need to fetch
  // lineItems from FTW backend for the EstimatedTransactionMaybe
  // In case you add more fields to the form, make sure you add
  // the values here to the bookingData object.
  handleOnChange(formValues) {
    const {
      startDate,
      endDate,
      duration,
      seats,
      quantity,
      durationFixed,
      listingId,
      isOwnListing,
      fetchLineItemsInProgress,
      onFetchTransactionLineItems,
      vatRate,
      extraServices,
      bedrooms,
      bookingSeats,
      useCorporatePrice,
    } = this.props;
    const values = formValues.values;

    let bedroomsPrice = 0;
    let bedroomsVat = 0;
    let extrasPrice = 0;
    let extrasVat = 0;
    for (let key in values) {
      if (key.includes('extras')) {
        const { amount, quantity } = values[key];
        extrasVat = vatHelper(extraServices, key, values, extrasVat, 'extrasName', 'extrasVat');
        extrasPrice += amount * quantity;
      }
      if (key.includes('bedroom')) {
        const { amount, quantity } = values[key];
        bedroomsVat = vatHelper(bedrooms, key, values, bedroomsVat, 'bedroomName', 'bedroomVat');
        bedroomsPrice += amount * quantity;
      }
    }

    const totalBedrooms = { amount: bedroomsPrice };
    const totalBedroomsVat = { amount: bedroomsVat };
    const totalExtraServices = { amount: extrasPrice };
    const totalExtrasVat = { amount: extrasVat };

    if (startDate && endDate && !fetchLineItemsInProgress) {
      onFetchTransactionLineItems({
        bookingData: {
          startDate,
          endDate,
          duration,
          seats,
          quantity,
          durationFixed,
          totalBedrooms,
          totalExtraServices,
          vatRate,
          totalBedroomsVat,
          totalExtrasVat,
          useCorporatePrice,
          ...(bookingSeats ? { bookingSeats } : {}),
        },
        listingId,
        isOwnListing,
      });
    }
  }

  clickShowMore() {
    this.setState({ showMore: !this.state.showMore });
  }

  render() {
    const {
      rootClassName,
      className,
      price: unitPrice,
      listingPrices,
      priceScheme,
      styles,
      structure,
      safeSpacing,
      listingCategory,
      spaceType,
      startDate,
      endDate,
      bedrooms,
      extraServices,
      duration,
      listingTitle,
      parentLisitngTitle,
      firstImage,
      currentAuthor,
      price,
      detailsSubTitle,
      isMobileLayout,
      showMore,
      isPerPerson,
      useNewStyle,
      showExtraServicesAndBedrooms,
      ...rest
    } = this.props;
    const classes = classNames(rootClassName || css.root, className);

    const isBedrooms = bedrooms && bedrooms.length > 0;
    const isExtraServices = extraServices && extraServices.length > 0;

    const extraServicesFilter =
      extraServices &&
      EXTRAS_TYPES.map((i) => {
        const key = i.key;
        return { value: extraServices.filter((j) => j.extrasType === key), label: i.key };
      });

    return (
      <FinalForm
        {...rest}
        onSubmit={this.handleFormSubmit}
        render={(fieldRenderProps) => {
          const {
            form,
            pristine,
            handleSubmit,
            intl,
            isOwnListing,
            listingId,
            submitButtonWrapperClassName,
            unitType,
            values,
            timeZone,
            lineItems,
            fetchLineItemsInProgress,
            fetchLineItemsError,
          } = fieldRenderProps;

          // This is the place to collect breakdown estimation data. See the
          // EstimatedBreakdownMaybe component to change the calculations
          // for customized payment processes.
          const bookingData =
            startDate && endDate
              ? {
                  unitType,
                  startDate,
                  endDate,
                  timeZone,
                }
              : null;

          const showEstimatedBreakdown =
            bookingData && lineItems && !fetchLineItemsInProgress && !fetchLineItemsError;

          const selectedBedroomsValues = selectedExtraValues(values, 'bedroom');
          const selectedExtraServicesValues = selectedExtraValues(values, 'extras');

          const selectedBedrooms = selectedBedroomsHelper(bedrooms, selectedBedroomsValues);
          const selectedExtraServices = selectedExtraServicesHelper(
            extraServices,
            selectedExtraServicesValues
          );

          const selectedExtras = selectedExtrasObj(values);

          const durationLabel = this.props.duration;

          const bookingInfoMaybe = showEstimatedBreakdown ? (
            <div className={css.bookingBreakdown}>
              {/* <h3 className={css.priceBreakdownTitle}>
                <FormattedMessage id="BookingTimeForm.priceBreakdownTitle" />
              </h3> */}
              <EstimatedBreakdownMaybe
                bookingData={bookingData}
                lineItems={lineItems}
                listingCategory={listingCategory}
                isPerSession={duration !== 'perHour'}
                isShowMore={true}
                isMobileLayout={isMobileLayout}
                clickShowMore={this.clickShowMore}
                showMore={this.state.showMore}
                isPerPerson={isPerPerson}
                useNewStyle={useNewStyle}
                showExtraServicesAndBedrooms={showExtraServicesAndBedrooms}
                selectedBedrooms={selectedBedrooms}
                selectedExtraServices={selectedExtraServices}
                selectedExtras={selectedExtras}
                durationLabel={durationLabel}
              />
            </div>
          ) : null;

          const loadingSpinnerMaybe = fetchLineItemsInProgress ? (
            <IconSpinner className={css.spinner} />
          ) : null;

          const bookingInfoErrorMaybe = fetchLineItemsError ? (
            <span className={css.sideBarError}>
              <FormattedMessage id="BookingDatesForm.fetchLineItemsError" />
            </span>
          ) : null;

          const submitButtonClasses = classNames(
            submitButtonWrapperClassName || css.submitButtonWrapper
          );

          return (
            <Form onSubmit={handleSubmit} className={classes}>
              <FormSpy
                onChange={(values) => {
                  this.handleOnChange(values);
                }}
              />

              <div className={css.contentContainer}>
                <div className={css.aspectWrapper}>
                  <ResponsiveImage
                    rootClassName={css.rootForImage}
                    alt={parentLisitngTitle}
                    image={firstImage}
                    variants={['landscape-crop', 'landscape-crop2x']}
                  />
                </div>
                {/* <div className={classNames(css.avatarWrapper, css.avatarMobile)}>
                  <AvatarMedium user={currentAuthor} disableProfileLink />
                </div> */}
                <div className={css.bookListingContainer}>
                  <div className={css.priceBreakdownContainer}>
                    {bookingInfoMaybe}
                    {loadingSpinnerMaybe}
                    {bookingInfoErrorMaybe}
                  </div>

                  {isExtraServices ? (
                    <div className={css.extrasContainer}>
                      <h1 className={css.headerExtras}>
                        <FormattedMessage id="BookingAddExtraForm.addExtra" />
                      </h1>
                      {extraServicesFilter.map((i, index) => (
                        <div key={index}>
                          {i.value.length > 0 ? (
                            <h2 className={css.subTitle}>
                              {language.labelsTranslator(i.label, intl)}
                            </h2>
                          ) : null}
                          {i.value.map(
                            (j, index) =>
                              console.log('j = ', j) || (
                                <InputRow
                                  i={j}
                                  index={index}
                                  intl={intl}
                                  type="extras"
                                  name="extras"
                                  key={j.id || j.extrasName}
                                  id={j.id || j.extrasName}
                                  form={form}
                                />
                              )
                          )}
                        </div>
                      ))}
                    </div>
                  ) : null}

                  {isBedrooms ? (
                    <div className={css.bedroomsContainer}>
                      <h1 className={css.headerExtras}>
                        <FormattedMessage id="BookingAddExtraForm.addBedrooms" />
                      </h1>
                      {bedrooms.map((i, index) => {
                        return (
                          <InputRow
                            i={i}
                            index={index}
                            intl={intl}
                            type="bedroom"
                            name="bedroom"
                            key={i.id || i.bedroomName}
                            id={i.id || i.bedroomName}
                            form={form}
                          />
                        );
                      })}
                    </div>
                  ) : null}

                  <div className={submitButtonClasses}>
                    <PrimaryButton type="submit" disabled={false} className={css.submitButton}>
                      <FormattedMessage id="BookingAddExtraForm.requestToBook" />
                    </PrimaryButton>
                  </div>
                </div>

                <div className={css.detailsContainerDesktop}>
                  <h1 className={css.summaryTitle}>
                    <FormattedMessage id="BookingAddExtraForm.summary" />
                  </h1>
                  <div className={css.bookingInfoContainer}>
                    <div className={css.detailsAspectWrapper}>
                      <ResponsiveImage
                        rootClassName={css.rootForImage}
                        alt={parentLisitngTitle}
                        image={firstImage}
                        variants={['landscape-crop', 'landscape-crop2x']}
                      />
                    </div>
                    {/* <div className={css.avatarWrapper}>
                      <AvatarMedium user={currentAuthor} disableProfileLink />
                    </div> */}
                    <div className={css.detailsHeadings}>
                      <h2 className={css.detailsParentTitle}>{parentLisitngTitle}</h2>
                      <h3 className={css.detailsTitle}>{listingTitle}</h3>
                      {price.amount > 0 ? (
                        <p className={css.detailsSubtitle}>{detailsSubTitle}</p>
                      ) : null}
                    </div>
                    {bookingInfoMaybe}
                    {loadingSpinnerMaybe}
                    {bookingInfoErrorMaybe}
                  </div>
                </div>
              </div>
            </Form>
          );
        }}
      />
    );
  }
}

BookingAddExtraFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  submitButtonWrapperClassName: null,
  isOwnListing: false,
  listingId: null,
  lineItems: null,
  fetchLineItemsError: null,
};

BookingAddExtraFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  submitButtonWrapperClassName: string,

  unitType: propTypes.bookingUnitType.isRequired,
  isOwnListing: bool,
  listingId: propTypes.uuid,

  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,

  // from injectIntl
  intl: intlShape.isRequired,
};

const BookingAddExtraForm = compose(injectIntl)(BookingAddExtraFormComponent);
BookingAddExtraForm.displayName = 'BookingAddExtraForm';

export default BookingAddExtraForm;
