import { types as sdkTypes } from './util/sdkLoader';

const { LatLng, LatLngBounds } = sdkTypes;

// An array of locations to show in the LocationAutocompleteInput when
// the input is in focus but the user hasn't typed in any search yet.
//
// Each item in the array should be an object with a unique `id` (String) and a
// `predictionPlace` (util.types.place) properties.
export default [
  {
    id: 'default-paris',
    predictionPlace: {
      bounds: new LatLngBounds(
        new LatLng(48.9021449, 2.4699208), // North-East corner
        new LatLng(48.815573, 2.224199) // South-West corner
      ),
    },
  },
  {
    id: 'default-lyon',
    predictionPlace: {
      bounds: new LatLngBounds(
        new LatLng(45.7971719, 4.9440104), // North-East corner
        new LatLng(45.6960142, 4.7683667) // South-West corner
      ),
    },
  },
  {
    id: 'default-lille',
    predictionPlace: {
      bounds: new LatLngBounds(
        new LatLng(50.6901789, 3.2131749), // North-East corner
        new LatLng(50.565256, 2.9261918) // South-West corner
      ),
    },
  },
  {
    id: 'default-reims',
    predictionPlace: {
      bounds: new LatLngBounds(
        new LatLng(49.3125712, 4.0911546), // North-East corner
        new LatLng(49.1616572, 3.9363371) // South-West corner
      ),
    },
  },
  {
    id: 'default-bordeaux',
    predictionPlace: {
      bounds: new LatLngBounds(
        new LatLng(44.9168975, -0.5158974), // North-East corner
        new LatLng(44.7950005, -0.65) // South-West corner
      ),
    },
  },
];
